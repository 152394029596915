<template>
   <v-container>
    <div v-show="isVisibleProp"></div>
    <v-row>
      <v-col cols="2">
        <v-select
          class="shared-source-fiscalYear"
          label="Fiscal Years"
          v-model="editedItem.fiscalYear"
          :items="fiscalYearRange"
        ></v-select>
      </v-col>
      <v-col cols="3">
        <v-select
          class="shared-source-type"
          label="Source Type"
          v-model="editedItem.type"
          :items="cmnSourceType"
          item-text="description"
          item-value="name"
        ></v-select>
      </v-col>
      <v-col cols="3">
        <v-combobox
          label="Source Number"
          class="shared-source-subSourceNumber"
          v-model="editedItem.sourceSubSourceNumber"
          :items="masterSources"
          @blur="onBlurOfSource"
        >
        </v-combobox>
      </v-col>
      <v-col cols="3">
        <v-text-field
          label="Alt Key"
          class="shared-source-altKey"
          v-model="editedItem.altKey"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="5">
        <v-text-field
          label="Description"
          class="shared-source-description"
          v-model="editedItem.sourceDescription"
        >
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          label="Sub Description"
          class="shared-source-subDescription"
          v-model="editedItem.subSourceDescription"
        >
        </v-text-field>
      </v-col>
      <v-col cols="3">
        <v-checkbox
          v-model="editedItem.isSkipMiscellaneousTransaction"
          label="Skip In Miscellaneous Transaction"
        >
        </v-checkbox>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapState,
  mapActions,
} from 'vuex';
import {
  getFYList,
} from '../../../util/shared/tmc-global';

export default {
  name: 'SourceEditedItem',
  components: {
  },
  props: {
    baseCrudKey: {
      type: String,
      default: 'source-dialog',
    },
  },
  data: () => ({
  }),
  created() {
    this.loadMasterList();
  },
  computed: {
    ...mapGetters('base/crud', [
      'item',
      'criteria',
      'items',
      'isVisible',
    ]),
    ...mapState({
      cmnSourceType: (state) => state.enums.cmnSourceTypeEnum || [],
      masterSourceList: (state) => state.shared.source.masterSourceList,
    }),
    ...mapState({
      fiscalYearRange: (state) => {
        const registerdYears = state.shared.source.regYears || {};
        return getFYList(registerdYears);
      },
    }),
    editedItem: {
      get() {
        return this.item(this.baseCrudKey);
      },
      set(value) {
        this.setItem([this.baseCrudKey, value]);
      },
    },
    masterSources() {
      const miscWhomsList = ((this.masterSourceList) || []).map(
        (r) => r.sourceNumber || '',
      );
      return miscWhomsList;
    },
    isVisibleProp() {
      const isVisible = this.isVisible(this.baseCrudKey);
      if (isVisible) {
        this.loadMasterList();
      }
      return isVisible;
    },
  },
  methods: {
    ...mapMutations('base/crud', [
      'setItem',
    ]),
    ...mapActions('shared/source', [
      'loadMasterSource',
    ]),
    async loadMasterList() {
      await this.loadMasterSource({
        criteria: {},
      });
    },
    onBlurOfSource() {
      const masterList = this.masterSourceList;
      if (this.editedItem.sourceSubSourceNumber) {
        const getMasterSource = (this.editedItem.sourceSubSourceNumber || '').split('.');
        for (let i = 0; i < (masterList || []).length; i += 1) {
          let masterDesc;
          if ((masterList[i].sourceNumber === getMasterSource[0])
            && masterList[i].sourceDescription) {
            this.editedItem.sourceDescription = masterList[i].sourceDescription;
            masterDesc = masterList[i].sourceDescription;
            this.editedItem.sourceDescription = masterDesc;
            return;
          }
        }
      }
    },
  },
};
</script>
