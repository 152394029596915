<template>
  <div>
    <v-container >
      <CRUDCriteria
        :showSearch="false"
        :showReset="true"
      >
        <template v-slot:criteriaRows>
          <v-row>
            <v-col cols="2">
              <v-select
                id="tmc-source-criteria-fy"
                label="By FY"
                :items="fiscalYearRange"
                v-model.number="criteriaState.fiscalYear"
                @input="searchByCriteria"
              ></v-select>
            </v-col>
            <v-col cols="5">
              <v-text-field
                id="sourceWildCard"
                label="Wildcard search (source number, type, desc or sub desc)"
                v-model="criteriaState.wildcard"
                @input="wildcardSearchDelayed"
                autocomplete="off"
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
      </CRUDCriteria>
      <CRUDList
        :canAdd="true"
        :canEdit="true"
        :canSave="true"
        :headers="headers"
        :baseCrudKey="baseCrudKey"
        :createNewItem="newItemDialog"
        :defaultSortBy="sortBy"
        toolbarTitle="Source"
        @loadCRUDList="handleLoadCRUDList"
        @upsertItem="upsrtSource"
      >
        <template v-slot:[`editedItem`]="{}">
          <SourceEditedItem :baseCrudKey="baseCrudKey"
          />
        </template>
      </CRUDList>
    </v-container>
  </div>
</template>

<script>
import {
  mapGetters,
  mapActions,
  mapState,
  mapMutations,
} from 'vuex';

import CRUDList from '../../common/base/crud/CRUDList.vue';
import CRUDCriteria from '../../common/base/crud/CRUDCriteria.vue';
import SourceEditedItem from './SourceEditedItem.vue';
import {
  toFiscalYear,
  getFYList,
} from '../../../util/shared/tmc-global';

const getHeaders = () => {
  const headers = [
    { text: 'Type', value: 'type', formatter: 'getFirstChar' },
    { text: 'Source', value: 'sourceSubSourceNumber' },
    { text: 'Description', value: 'sourceDescription' },
    { text: 'Sub Description', value: 'subSourceDescription' },
  ];
  return headers;
};

export default {
  name: 'SourceList',
  components: {
    CRUDCriteria,
    CRUDList,
    SourceEditedItem,
  },
  data: () => ({
    baseCrudKey: 'sharedSource',
    delayTimerId: undefined,
    headers: [],
    sortBy: [
      {
        column: 'sourceSubSourceNumber',
        direction: 'asc',
      },
    ],
  }),
  created() {
    this.headers = getHeaders();
    this.initCriteria();
    this.criteriaState.fiscalYear = toFiscalYear(new Date(this.todaysDate));
    this.loadFiscalRegYears();
  },
  computed: {
    ...mapGetters('base/crud', [
      'criteria',
      'index',
      'item',
    ]),
    ...mapGetters(['todaysDate']),
    ...mapState({
      fiscalYearRange: (state) => {
        const registerdYears = state.shared.source.regYears || {};
        return getFYList(registerdYears);
      },
    }),
    criteriaState: {
      get() {
        return this.criteria(this.baseCrudKey);
      },
      set(value) {
        this.setCriteria([this.baseCrudKey, value]);
      },
    },
  },
  methods: {
    ...mapActions('shared/source', [
      'loadCmnSource',
      'upsertCmnSource',
      'loadFiscalRegYears',
    ]),
    ...mapMutations('base/crud', [
      'setCriteria',
    ]),
    handleLoadCRUDList() {
      const { baseCrudKey } = this;
      const criteria = this.criteriaState;
      this.loadCmnSource({
        criteria,
        baseCrudKey,
      });
    },
    searchByCriteria() {
      this.handleLoadCRUDList();
    },
    isNewItem() {
      return this.index(this.baseCrudKey) === -1;
    },
    upsrtSource(item) {
      const { baseCrudKey } = this;
      if (!this.isNewItem()) {
        if (!window.confirm('This update will reflect to all associated places, are you sure!')) {
          return false;
        }
      }
      this.upsertCmnSource({
        item,
        baseCrudKey,
      });
      return true;
    },
    initCriteria() {
      // reset criteria while merge flag is true
      this.criteriaState = {};
    },
    newItemDialog() {
      let newItem = {};
      newItem = {
        fiscalYear: toFiscalYear(new Date(this.todaysDate)),
        sourceDescription: null,
        subSourceDescription: null,
        sourceSubSourceNumber: null,
        type: null,
      };
      return newItem;
    },
    wildcardSearchDelayed() {
      // cancel pending call
      clearTimeout(this.delayTimerId);
      const { baseCrudKey } = this;
      // delay new call
      this.delayTimerId = setTimeout(async () => {
        await this.loadCmnSource({
          criteria: this.criteriaState, baseCrudKey,
        });
      }, 250);
    },
  },
};

</script>
