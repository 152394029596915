<template>
  <div>
    <v-container >
      <SourceList/>
    </v-container>
  </div>
</template>

<script>
import SourceList from '../../components/shared/source/SourceList.vue';

export default {
  name: 'MiscSource',
  components: {
    SourceList,
  },
  data: () => ({
  }),
  created() {
  },
  computed: {
  },
  methods: {
  },
};

</script>
