<template>
  <div
    class="elevation-3 ma-3 pa-3 crud-criteria"
    id="crud-criteria-div"
    v-show="!isPrintScreen"
  >
    <h3>Criteria</h3>
    <div>
      <slot name="criteriaRows">
        <v-row dense>
          <v-col cols="2">
            (no criteria)
          </v-col>
        </v-row>
      </slot>
      <v-row dense>
        <v-spacer></v-spacer>
        <slot v-if="showAdditionalActions" name="additionalActions">
          (no additional actions)
        </slot>
        <v-col cols="2">
          <v-btn v-if="showReset" color="primary"
            rounded
            dense
            class= "tmc-crud-criteria-reset"
            @click="resetCriteria">
            <v-icon class="mr-2">{{icons.mdiReload}}</v-icon>
            Reset
          </v-btn>
        </v-col>
        <v-col v-if="showSearch" cols="2">
          <v-btn color="primary"
            rounded
            dense
            @click="searchByCriteria">
            <v-icon class="mr-2">{{icons.mdiMagnify}}</v-icon>
            Search
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>

import {
  mapMutations,
  mapGetters,
  mapState,
} from 'vuex';
import {
  mdiReload,
  mdiMagnify,
} from '@mdi/js';

export default {
  name: 'CRUDCriteria',
  data: () => ({
    icons: {
      mdiReload,
      mdiMagnify,
    },
  }),
  props: {
    baseCrudKey: {
      type: String,
      default: 'default',
    },
    showReset: {
      type: Boolean,
      default: true,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    showAdditionalActions: {
      type: Boolean,
      default: false,
    },
    // parent business logic should tell how to reset
    resetCriteria: {
      type: Function,
      default: () => {
        // ☢️ nuclear option - full page reload to reset all components
        // (reliable, but slow - consider overriding this from parent)
        window.location.reload();
      },
    },
    // parent business logic should tell how to search
    searchByCriteria: {
      type: Function,
      default: () => {},
    },
  },
  // in parent component's business-specific logic, can:
  // load lists of things needed (like a list of depts, whats, etc)
  // and use those from the criteriaRows slot
  // created() {},
  mounted() {
    // business logic for focus, etc
    this.$emit('mounted', this);
  },
  methods: {
    ...mapMutations('base/crud', [
      // 'setCriteria',
    ]),
  },
  computed: {
    ...mapGetters('base/crud', [
      // 'criteria',
    ]),
    ...mapState({
      isPrintScreen: (state) => state.isPrintScreen,
    }),
  },
};
</script>
